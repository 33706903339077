/* --------------------------------------------------------- */
/* ------------------------ PLANNING ----------------------- */
/* --------------------------------------------------------- */

.planning-row.active {
  background-color: lightgreen;
}

/* --------------------------------------------------------- */
/* ------------------------ SCHEDULER ---------------------- */
/* --------------------------------------------------------- */

.scheduler-container {
  width: 100%;
}

.watermark {
  width: 200px;
  height: 200px;
  display: block;
  position: relative;
}

.watermark::after {
  content: "";
  background-image: linear-gradient(
    135deg,
    #ffffff 25%,
    #9e9e9e 25%,
    #9e9e9e 50%,
    #ffffff 50%,
    #ffffff 75%,
    #9e9e9e 75%,
    #9e9e9e 100%
  );
  background-size: 8.14px 8.14px;
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.scheduler-day {
  margin-top: 10px;
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding-bottom: 10px;
  font-size: x-small !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduler-hour {
  position: relative;
  top: -15px;
  flex: 0 0 5%;
  height: 15px;
  max-width: 5%;
  padding-right: 10px;
  padding-top: 5px;
  font-size: x-small !important;
}

.scheduler-row-header {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduler-row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduler-row-col {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.scheduler-content {
  display: flex;
  flex: 0 0 12.5%;
  max-width: 12.5%;
  height: 15px;
  background-color: white;
  border: 1px solid whitesmoke;
}

.scheduler-content-round-hour {
  border-top: 1px solid grey;
}

.scheduler-content.selected {
  background-color: lightgreen;
}

.recorded {
  background-color: #32c671;
}

.addTimeStamp-item {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 64px;
  border-radius: 60px;
  border: 2px solid whitesmoke;
}

.addTimeStamp-item:hover {
  background-color: whitesmoke;
}

.addTimeStamp-item.selected {
  background-color: #32c671;
}

.addTimeStamp-item-img-container {
  flex: 0 0 70px;
  max-width: 70px;
  width: 100px;
}

.img-round-small {
  width: 60px;
  height: auto;
  border-radius: 50%;
}

.avatar-round-small {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  color: black;
}

.smaller-avatar {
  width: 30px !important;
  height: 30px !important;
}

.addTimeStamp-item-label-container {
  flex: 0 0 500px;
  max-width: 500px;
  padding-top: 5px;
}
.schedule-legend {
  display: flex;
}

.Scheduler-legend-row {
  display: flex;
  margin: 5px 10px;
}

.Scheduler-legend-row-label {
  display: flex;
  justify-content: justify;
  align-items: left;
  padding: 2px;
}
